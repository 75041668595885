.main-card {
    display: flex;
    flex-wrap: wrap;
}

.btn-group-responsive {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 768px) {
        & {
            flex-direction: column;
            gap: 10px;
            min-width: 50vw;
        }
    }
}